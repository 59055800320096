import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/auth-header-image.svg'


const _hoisted_1 = { class: "desktop-panel" }
const _hoisted_2 = { class: "auth-intro-header" }

import useAuth from "@/componentables/auth";
import { IonPage, IonContent, IonInput, IonButton } from "@ionic/vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { ref, watch } from "vue";
import Toast from "@/componentables/toast";
import FloatingBackButton from "@/components/FloatingBackButton.vue";
import { useI18n } from "vue-i18n";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { SplashScreen } from "@capacitor/splash-screen";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthLogin',
  setup(__props) {

const form = ref({
  username: "",
  password: "",
});

const router = useRouter();
const route = useRoute();
const auth = useAuth();
const toast = new Toast();
const { t } = useI18n();

const handleHapticFeedback = () => {
  Haptics.impact({ style: ImpactStyle.Medium });
};

watch(
  () => route.query.auth_token,
  async () => {
    if (route.query.auth_token) {
      SplashScreen.show();
      auth._setToken({
        access_token: route.query.auth_token as string,
        expires_in: "3600000",
        refresh_token: "not_present",
      });
      await redirect();
      SplashScreen.hide();
    }
  },
  { immediate: true },
);

async function handleLogin() {
  try {
    await auth.login(form.value);

    await redirect();
  } catch (error: any) {
    if (error.response?.status === 400) {
      await toast.open(t("auth.login.wrong-login"));
    }
  }
}

async function redirect() {
  const url = await auth.getRedirectAfterLogin(
    route.query.redirect ? { path: route.query.redirect as string } : null,
  );
  if (
    typeof url === "string" &&
    (url.includes("http://") || url.includes("https://"))
  ) {
    window.location.href =
      url +
      (url.includes("?") ? "&" : "?") +
      `auth_token=${await auth.getToken()}`;
  } else {
    await router.push(url);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[3] || (_cache[3] = _createElementVNode("img", {
              alt: "Sportspoule patroon",
              class: "auth-header-image",
              src: _imports_0
            }, null, -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$t("auth.login.title")), 1)
            ]),
            _createElementVNode("form", {
              class: "auth",
              onSubmit: _withModifiers(handleLogin, ["prevent"])
            }, [
              _createVNode(_unref(IonInput), {
                id: "username",
                modelValue: form.value.username,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.username) = $event)),
                placeholder: _ctx.$t('fields.email'),
                name: "email",
                required: ""
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_unref(IonInput), {
                id: "password",
                modelValue: form.value.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.password) = $event)),
                placeholder: _ctx.$t('fields.password'),
                name: "password",
                required: "",
                type: "password"
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_unref(IonButton), {
                class: "btn",
                expand: "block",
                type: "submit",
                onClick: handleHapticFeedback
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("auth.login.submit")), 1)
                ]),
                _: 1
              }),
              _createElementVNode("a", {
                class: "forgot-password",
                onClick: _cache[2] || (_cache[2] = () => _unref(router).push({ name: 'auth.forgot-password' }))
              }, _toDisplayString(_ctx.$t("auth.login.forgot")), 1)
            ], 32)
          ]),
          _createVNode(FloatingBackButton)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})