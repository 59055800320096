import Group from "@/models/Group";
import ApiService from "@/services/api.service";
import { ApiResponse } from "@/types/ApiResponse";
import { RouteParamValue } from "vue-router";

const GroupService = {
  store: async (data: object) => {
    return ApiService.put(`/groups`, data);
  },

  find: async (id: string) =>
    ApiService.get("groups/" + id)
      .then((response) => new Group(response.data))
      .catch((error) => {
        throw error;
      }),

  openShareLink: (shareLink: string) =>
    ApiService.post("/group/share", { shareLink }, undefined) as Promise<
      ApiResponse<Group>
    >,
  getPouleNameByShareLink: (shareLink: string) =>
    ApiService.get(`group/share/name/${shareLink}`).then(
      (response) => response.data.name,
    ),

  index: async () => {
    const response = await ApiService.get("/groups");

    return response.data.map((group: Group) => new Group(group));
  },

  loadMatches: async (id: string, page: number, onlyPlayed: boolean) => {
    const params = {
      page,
      played: onlyPlayed ? true : undefined,
      perPage: 7,
    };

    if (onlyPlayed) {
      params.played = true;
    }

    return ApiService.get(`/group/${id}/matches`, params)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  loadRaces: async (groupId: string, onlyPlayed: boolean) => {
    const params = {
      played: onlyPlayed ? true : undefined,
    };

    if (onlyPlayed) {
      params.played = true;
    }

    return ApiService.get(`/group/${groupId}/races`, params)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  loadRace: async (groupId: string, raceId: string) => {
    return ApiService.get(`/group/${groupId}/races/${raceId}`)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },

  availableTournaments: async (sport: string) => {
    if (sport === "formula1") {
      return [
        {
          id: "formula1",
          name: "Formula 1",
          seasons: [],
        },
      ];
    }
    const response = await ApiService.get(sport + "/seasons");

    return response.data.data;
  },
  leave: async (id: string) => {
    return ApiService.delete(`/groups/${id}/remove/me`);
  },
  updatePrediction: async (param: {
    driver_8_id: any;
    driver_9_id: any;
    driver_6_id: any;
    driver_7_id: any;
    driver_5_id: any;
    driver_4_id: any;
    driver_1_id: any;
    driver_2_id: any;
    driver_3_id: any;
    driver_10_id: any;
    group_id: string | RouteParamValue[];
    race_id: any;
    pole_driver_id: any;
    fastest_lap_driver_id: any;
  }) => {
    console.log(param);
    return ApiService.put(
      `/group/${param.group_id}/races/${param.race_id}/predict`,
      param,
    )
      .then((response) => response.data)
      .catch((error) => {
        alert(error);
      });
  },
};

export default GroupService;
