<template>
  <ion-page>
    <ion-content>
      <div class="desktop-panel">
        <img
          alt="Sportspoule patroon"
          class="auth-header-image"
          src="@/assets/images/auth-header-image.svg"
        />
        <div class="auth-intro-header">
          <h1>{{ $t("auth.login.title") }}</h1>
        </div>
        <form class="auth" @submit.prevent="handleLogin">
          <ion-input
            id="username"
            v-model="form.username"
            :placeholder="$t('fields.email')"
            name="email"
            required
          >
          </ion-input>
          <ion-input
            id="password"
            v-model="form.password"
            :placeholder="$t('fields.password')"
            name="password"
            required
            type="password"
          >
          </ion-input>
          <ion-button
            class="btn"
            expand="block"
            type="submit"
            @click="handleHapticFeedback"
          >
            {{ $t("auth.login.submit") }}
          </ion-button>
          <a
            class="forgot-password"
            @click="() => router.push({ name: 'auth.forgot-password' })"
          >
            {{ $t("auth.login.forgot") }}
          </a>
        </form>
      </div>
      <floating-back-button />
    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import useAuth from "@/componentables/auth";
import { IonPage, IonContent, IonInput, IonButton } from "@ionic/vue";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import { ref, watch } from "vue";
import Toast from "@/componentables/toast";
import FloatingBackButton from "@/components/FloatingBackButton.vue";
import { useI18n } from "vue-i18n";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { SplashScreen } from "@capacitor/splash-screen";

const form = ref({
  username: "",
  password: "",
});

const router = useRouter();
const route = useRoute();
const auth = useAuth();
const toast = new Toast();
const { t } = useI18n();

const handleHapticFeedback = () => {
  Haptics.impact({ style: ImpactStyle.Medium });
};

watch(
  () => route.query.auth_token,
  async () => {
    if (route.query.auth_token) {
      SplashScreen.show();
      auth._setToken({
        access_token: route.query.auth_token as string,
        expires_in: "3600000",
        refresh_token: "not_present",
      });
      await redirect();
      SplashScreen.hide();
    }
  },
  { immediate: true },
);

async function handleLogin() {
  try {
    await auth.login(form.value);

    await redirect();
  } catch (error: any) {
    if (error.response?.status === 400) {
      await toast.open(t("auth.login.wrong-login"));
    }
  }
}

async function redirect() {
  const url = await auth.getRedirectAfterLogin(
    route.query.redirect ? { path: route.query.redirect as string } : null,
  );
  if (
    typeof url === "string" &&
    (url.includes("http://") || url.includes("https://"))
  ) {
    window.location.href =
      url +
      (url.includes("?") ? "&" : "?") +
      `auth_token=${await auth.getToken()}`;
  } else {
    await router.push(url);
  }
}
</script>

<style lang="scss" scoped>
.auth-intro-header {
  position: relative;
  padding: 168px 24px 48px 24px;

  h1 {
    font-size: vw(32px);
    text-align: center;
  }
}

form {
  ion-input {
    margin-bottom: 8px;
  }

  .btn {
    margin-top: vw(8px);
    margin-bottom: vw(48px);
  }

  .forgot-password {
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }
}

.floating-back-button {
  position: absolute;
  top: 64px;
  left: 16px;
}
</style>
