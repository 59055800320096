import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icons/arrow-back.svg'


import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'FloatingBackButton',
  setup(__props) {

const router = useRouter();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "floating-back-button",
    onClick: _cache[0] || (_cache[0] = () => _unref(router).back())
  }, _cache[1] || (_cache[1] = [
    _createElementVNode("img", { src: _imports_0 }, null, -1)
  ])))
}
}

})