import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { computed } from "vue";
import { Capacitor } from "@capacitor/core";
import { Haptics, ImpactStyle } from "@capacitor/haptics";


export default /*@__PURE__*/_defineComponent({
  __name: 'SignInWithButton',
  props: {
  driver: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const logoSrc = computed(() => {
  return require(`@/assets/images/logo-${props.driver}.svg`);
});

function capitalizeFirstLetter(inputString: string) {
  return inputString[0].toUpperCase() + inputString.slice(1);
}

function redirect() {
  const redirectSchema = Capacitor.isNativePlatform()
    ? "sportspoule://"
    : process.env.VUE_APP_BASE_URL;
  const redirectUrl = `${redirectSchema}/login`;
  Haptics.impact({ style: ImpactStyle.Medium });
  window.location.href = `${process.env.VUE_APP_API_URL}/auth/redirect/${props.driver}?redirect_url=${redirectUrl}`;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([__props.driver, "btn sso"]),
    onClick: redirect
  }, [
    _createElementVNode("img", {
      src: logoSrc.value,
      class: "logo"
    }, null, 8, _hoisted_1),
    _createTextVNode(" " + _toDisplayString(_ctx.$t("components.continue-with", { driver: capitalizeFirstLetter(__props.driver) })), 1)
  ], 2))
}
}

})