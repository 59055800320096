import ApiService from "@/services/api.service";
import { ApiResponse } from "@/types/ApiResponse";
import moment, { Moment } from "moment";
import { reactive } from "vue";

export type Attributes = { [key: string]: any };
export type Cast = (attributes: Attributes, key: string) => any;
export type Casts = { [key: string]: Cast };

export default function Model<T>() {
  class Model {
    id?: number;
    public attributes: Attributes = reactive({});
    created_at?: Moment;
    updated_at?: Moment;

    public static api_url: string;
    public casts: Casts = {};
    public dates: string[] = ["created_at", "updated_at"];

    constructor(data: object) {
      Object.assign(this.attributes, data);
      return new Proxy(this, this);
    }

    public static async index(params = {}): Promise<ApiResponse<T[]>> {
      const response = (await ApiService.get(this.api_url, params)).data;
      response.data = response.data.map((item: object) => new this(item));

      return response;
    }

    public static async show(id: number): Promise<T> {
      const response = (await ApiService.get(`${this.api_url}/${id}`))
        .data as ApiResponse<object>;

      return new this(response.data) as T;
    }

    getCasts(): Casts {
      const dateCast: Cast = (attributes, key) =>
        attributes[key] ? moment(attributes[key]) : null;

      return {
        ...this.dates.reduce((acc, field) => {
          acc[field] = dateCast;

          return acc;
        }, {} as any),
        ...this.casts,
      };
    }

    get(target: any, prop: string) {
      const casts = this.getCasts();
      const cast = casts[prop];

      if (prop in target.attributes || prop in casts) {
        return cast ? cast(target.attributes, prop) : target.attributes[prop];
      }

      return target[prop];
    }
  }

  return Model;
}
