import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ApiService from "@/services/api.service";
import { IonicVue } from "@ionic/vue";
import i18n from "./i18n";
import useAuth from "@/componentables/auth";
import Echo from "laravel-echo";
import Pusher from "pusher-js";
import * as Sentry from "@sentry/capacitor";
import * as SentryVue from "@sentry/vue";

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme variables */
import "./assets/scss/main.scss";
import { LiveUpdate } from "@capawesome/capacitor-live-update";
import { Capacitor } from "@capacitor/core";

/* plugins */
ApiService.init(process.env.VUE_APP_API_URL);

declare global {
  interface Window {
    Pusher: typeof Pusher; // You can replace `any` with the actual type if you know it.
    Echo: Echo<"reverb">;
  }
}

// make this ts set this on window
window.Pusher = Pusher;
window.Echo = new Echo({
  broadcaster: "reverb",
  key: process.env.VUE_APP_REVERB_APP_KEY ?? "qyee9aku6sr5uknt1ojo",
  wsHost:
    process.env.VUE_APP_REVERB_HOST ??
    "ws." +
      process.env.VUE_APP_API_URL.replace(/^https?:\/\//, "").replace(
        /^http?:\/\//,
        "",
      ),
  wsPort: process.env.VUE_APP_REVERB_PORT ?? 443,
  wssPort: process.env.VUE_APP_REVERB_PORT ?? 443,
  forceTLS: (process.env.VUE_APP_REVERB_SCHEME ?? "https") === "https",
  enabledTransports: ["ws", "wss"],
});

const auth = useAuth();
if (await auth.isAuthenticated()) {
  ApiService.setHeader();
  ApiService.mountRequestInterceptor();
  ApiService.mount401Interceptor();
}
ApiService.mountResponseInterceptor();

const app = createApp(App).use(IonicVue).use(router).use(i18n);

router.isReady().then(() => {
  app.mount("#app");
});

const version = Capacitor.isNativePlatform()
  ? (await LiveUpdate.getCurrentBundle()).bundleId
  : null;

Sentry.init(
  {
    app,
    enabled: ["production", "acceptation"].includes(process.env.NODE_ENV),
    dsn: "https://240798335ca454081422b7433086ba28@o4508692096614400.ingest.de.sentry.io/4508692100153424",
    // Set your release version, such as "getsentry@1.0.0"
    release: "sportspoule@" + version,
    // Set your dist version, such as "1"
    dist: "<dist>",
    integrations: [
      Sentry.browserTracingIntegration() as any,
      Sentry.replayIntegration() as any,
    ],
    tracesSampleRate: 0.2,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [process.env.VUE_APP_API_URL],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    // Learn more at
    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
  SentryVue.init,
);
