import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import "vue-router";
import HomeTabs from "@/views/HomeTabs.vue";
import Auth from "./routes/auth";
import Profile from "./routes/profile";
import Livescore from "./routes/livescores";
import Soccer from "./routes/soccer";
import authMiddleware from "./utils/middleware/_auth";

// Logged in
import "vue-router";

declare module "vue-router" {
  interface RouteMeta {
    // is optional
    middleware?: (context: any) => void;
  }
}

const routes: Array<RouteRecordRaw> = [
  ...Auth,
  {
    path: "/",
    redirect: "/livescores",
    meta: {
      middleware: authMiddleware,
    },
    component: HomeTabs,
    children: [
      ...Profile,
      ...Livescore,
      ...Soccer,
      // home route stays outside of profile because it wil change
      {
        name: "groups.index",
        path: "/groups",
        component: () => import("@/views/groups/GroupIndex.vue"),
      },
      {
        name: "auth.invited-by-link",
        path: "/poules/share/:token",
        component: () => import("@/views/groups/GroupJoin.vue"),
      },
      {
        name: "groups.show",
        path: "/groups/:groupId",
        component: () => import("@/views/groups/GroupShow.vue"),
      },
      {
        name: "groups.create",
        path: "/groups/create",
        component: () => import("@/views/groups/GroupCreate.vue"),
      },
      {
        name: "groups.formula1.show",
        path: "groups/formula1/:groupId",
        component: () => import("@/views/groups/formula1/GroupShow.vue"),
      },
      {
        name: "groups.formula1.race.show",
        path: "groups/formula1/:groupId/race/:raceId",
        component: () => import("@/views/groups/formula1/RaceShow.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(""),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }

  const context = {
    to,
    from,
    next,
  };

  return to.meta.middleware({
    ...context,
  });
});

export default router;
