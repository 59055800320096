import Model, { Casts } from "./Model";

export default class User extends Model<User>() {
  name?: string;
  profile_picture?: string;

  public casts: Casts = {
    profilePictureUrl: (attributes) =>
      attributes.profile_picture
        ? `${process.env.VUE_APP_API_URL}/${attributes.profile_picture}`
        : null,
  };
}
