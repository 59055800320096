<template>
  <ion-page>
    <ion-content>
      <div class="desktop-panel">
        <img
          alt="Sportspoule patroon"
          class="auth-header-image"
          src="@/assets/images/auth-header-image.svg"
        />
        <div class="wrapper">
          <div :class="{ hide: emailIsSent }" class="auth-intro-header">
            <h1>{{ $t("auth.forgot-password.title") }}</h1>
          </div>
          <div
            :class="{ show: emailIsSent }"
            class="auth-intro-header email-sent"
          >
            <img src="@/assets/images/sportspoule-logo.svg" />
            <p class="message">
              {{ $t("auth.forgot-password.email-sent") }}
            </p>
          </div>
        </div>
        <form
          :class="{ hide: emailIsSent }"
          class="auth"
          @submit.prevent="handleForgotPassword"
        >
          <ion-input
            id="email"
            v-model="email"
            name="email"
            :placeholder="$t('fields.email')"
            required
          >
          </ion-input>
          <ion-button class="btn" expand="block" type="submit">
            {{ $t("auth.forgot-password.cta") }}
          </ion-button>
        </form>
      </div>
      <floating-back-button />
    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import { IonButton, IonInput, IonContent, IonPage } from "@ionic/vue";
import { ref } from "vue";
import useAuth from "@/componentables/auth";
import FloatingBackButton from "@/components/FloatingBackButton.vue";

const email = ref("");
const auth = useAuth();
const emailIsSent = ref(false);

const handleForgotPassword = () => {
  auth.forgotPassword(email.value).then(() => {
    emailIsSent.value = true;
  });
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;

  .auth-intro-header {
    position: relative;
    padding: 168px 24px 48px 24px;
    transition: all 0.4s ease-out 0.1s;

    &.hide {
      opacity: 0;
      transform: translateX(-32px);
    }

    &.email-sent {
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      flex-direction: column;
      opacity: 0;
      transform: translateX(32px);
      transition: all 0.5s ease-out 0.4s;

      &.show {
        opacity: 1;
        transform: translateX(0);
      }

      img {
        margin-bottom: vw(40px);
      }

      p {
        font-size: 18px;
        font-weight: 600;
        line-height: 140%;
        text-align: center;
      }
    }

    h1 {
      font-size: vw(32px);
      text-align: center;
    }
  }
}

form {
  &.hide {
    opacity: 0;
    transform: translateX(-32px);
    transition: all 0.6s ease-out 0.2s;
  }

  .btn {
    margin-top: vw(8px);
  }
}

.floating-back-button {
  position: absolute;
  top: 16px;
  left: 16px;
}

.ios {
  .floating-back-button {
    // Because of the statusbar.
    top: 64px;
  }
}
</style>
