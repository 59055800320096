import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/auth-header-image.svg'


const _hoisted_1 = { class: "desktop-panel" }
const _hoisted_2 = { class: "auth-intro-header" }

import useAuth from "@/componentables/auth";
import { IonPage, IonContent, IonInput } from "@ionic/vue";
import { useRouter } from "vue-router";
import { ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthSetNewPassword',
  setup(__props) {

const router = useRouter();
const auth = useAuth();

const form = ref({
  password: "",
  password_confirmation: "",
  token: router.currentRoute.value.query.token as string,
  email: router.currentRoute.value.query.email as string,
});

async function handleResetPassword() {
  await auth.resetPassword(form.value);

  router.push({ name: "auth.login" });
}

return (_ctx: any,_cache: any) => {
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createBlock(_unref(IonPage), null, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _cache[2] || (_cache[2] = _createElementVNode("img", {
              alt: "Sportspoule patroon",
              class: "auth-header-image",
              src: _imports_0
            }, null, -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h1", null, _toDisplayString(_ctx.$t("auth.forgot-password.new-password")), 1)
            ]),
            _createElementVNode("form", {
              class: "auth",
              onSubmit: _withModifiers(handleResetPassword, ["prevent"])
            }, [
              _createVNode(_unref(IonInput), {
                id: "password",
                modelValue: form.value.password,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.password) = $event)),
                name: "password",
                placeholder: _ctx.$t('auth.forgot-password.new-password'),
                required: "",
                type: "password"
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_unref(IonInput), {
                id: "password",
                modelValue: form.value.password_confirmation,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.value.password_confirmation) = $event)),
                name: "password",
                placeholder: _ctx.$t('auth.forgot-password.repeat-new-password'),
                required: "",
                type: "password"
              }, null, 8, ["modelValue", "placeholder"]),
              _createVNode(_component_ion_button, {
                class: "btn",
                expand: "block",
                shape: "block",
                type: "submit"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("auth.forgot-password.cta-set")), 1)
                ]),
                _: 1
              })
            ], 32)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})