<template>
  <div :class="driver" class="btn sso" @click="redirect">
    <img :src="logoSrc" class="logo" />
    {{
      $t("components.continue-with", { driver: capitalizeFirstLetter(driver) })
    }}
  </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { Capacitor } from "@capacitor/core";
import { Haptics, ImpactStyle } from "@capacitor/haptics";

const props = defineProps({
  driver: {
    type: String,
    required: true,
  },
});

const logoSrc = computed(() => {
  return require(`@/assets/images/logo-${props.driver}.svg`);
});

function capitalizeFirstLetter(inputString: string) {
  return inputString[0].toUpperCase() + inputString.slice(1);
}

function redirect() {
  const redirectSchema = Capacitor.isNativePlatform()
    ? "sportspoule://"
    : process.env.VUE_APP_BASE_URL;
  const redirectUrl = `${redirectSchema}/login`;
  Haptics.impact({ style: ImpactStyle.Medium });
  window.location.href = `${process.env.VUE_APP_API_URL}/auth/redirect/${props.driver}?redirect_url=${redirectUrl}`;
}
</script>
