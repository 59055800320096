<template>
  <ion-modal
    ref="modal"
    :breakpoints="[0, 0.75, 1]"
    :initial-breakpoint="0.75"
    @didPresent="refreshGroups"
    trigger="open-group-navigation"
  >
    <ion-content>
      <div class="modal-content">
        <div class="top-bar">
          <h2>
            {{ $t("group.index.title") }}
          </h2>
        </div>
        <div class="members">
          <div
            v-for="group of groups"
            :key="group.id"
            class="member"
            @click="goToGroup(group.id, group.sport)"
          >
            <div class="profile-image-container">
              <img
                v-if="group.avatar"
                :alt="group.name"
                :src="group.avatarUrl"
              />
              <div v-else class="profile-image-placeholder">
                {{ initials(group.name) }}
              </div>
            </div>
            <span class="name">{{ group.name }}</span>
          </div>
        </div>
        <div class="btn-rapper">
          <router-link
            :to="{ name: 'groups.create' }"
            @click="modalController.dismiss()"
            class="btn"
          >
            {{ $t("group.index.create-new-group") }}
          </router-link>
        </div>
      </div>
    </ion-content>

    <modal-closer />
  </ion-modal>
</template>

<script lang="ts" setup>
import { IonContent, IonModal, useIonRouter } from "@ionic/vue";
import { onMounted, ref } from "vue";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import GroupService, { Group } from "@/services/group.service";
import { modalController } from "@ionic/core";
import ModalCloser from "@/components/ModalCloser.vue";

const ionRouter = useIonRouter();
// On mounted, get the groups the user is a member of (index groups)
const groups = ref<Group[]>([]);
const modal = ref();
onMounted(async () => {
  refreshGroups();
});

const refreshGroups = async () => {
  groups.value = await GroupService.index();
};

const cancel = () => {
  modal.value.$el.dismiss({
    dismissed: true,
  });
};

const goToGroup = (id: number, sport: string) => {
  cancel();

  Haptics.impact({ style: ImpactStyle.Medium });

  if (sport === "racing") {
    ionRouter.push({ name: "groups.formula1.show", params: { groupId: id } });
  } else {
    ionRouter.push({ name: "groups.show", params: { groupId: id } });
  }
};

function initials(fullName: string) {
  // Create an array from fullName
  const arrName = fullName.split(" ");

  // Select the first letter of the first name
  const firstName = fullName.charAt(0);

  // Select the first letter of the lastname
  const lastName = arrName[arrName.length - 1].charAt(0);

  // Return the initials
  return firstName.toUpperCase() + lastName.toUpperCase();
}
</script>

<style lang="scss" scoped>
.modal-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow-y: auto;
  background: #f8f8fc;

  .top-bar {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 80px;
    background: white;

    h2 {
      line-height: 1;
    }
  }

  .info-text {
    margin-bottom: 40px;
    width: 100%;
    padding: 0 16px;
  }

  .section-wrapper {
    margin: 0 0 48px 0;
    width: 100%;
    padding: 0 16px;

    .upsell-container {
      display: flex;
      flex-direction: column;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      width: 100%;
      padding: 32px 16px;
      background: white;
      gap: 8px;

      h2 {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 800;
        line-height: 120%;
        letter-spacing: -0.02em;
        color: var(--color-primary);
      }

      .bar-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
        font-size: 12px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.02em;

        .bar-wrapper {
          position: relative;
          overflow: hidden;
          margin-bottom: 8px;
          border-radius: 2px;
          width: 100%;
          height: 4px;
          background: linear-gradient(
            193.41deg,
            rgba(51, 119, 255, 0.1) 3.87%,
            rgba(102, 153, 255, 0.1) 99.12%
          );

          .bar {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 2px;
            height: 100%;
            background: linear-gradient(
              193.41deg,
              #3377ff 3.87%,
              #6699ff 99.12%
            );
          }
        }

        .flex {
          display: flex;
        }

        span.members {
          margin-bottom: 0;
          border: none;
        }
      }
    }
  }

  .members {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 0;
    background: white;
    gap: 0;

    .member {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 16px 16px;
      gap: 8px;
      cursor: pointer;

      .number {
        width: 24px;
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        color: rgba(black, 0.5);
      }

      .profile-image-container {
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 100px;
        width: 32px;
        height: 32px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .profile-image-placeholder {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          background: var(--color-primary);
          font-size: 12px;
          font-weight: bold;
          line-height: 100%;
          color: white;
        }
      }

      .name,
      .admin {
        font-size: 14px;
        font-weight: 600;
        line-height: 100%;
        color: black;
      }

      .admin {
        opacity: 0.3;
      }

      .points {
        margin: 0 0 0 auto;
        font-size: 16px;
        font-weight: bold;
        line-height: 100%;
        color: var(--color-primary);
      }
    }
  }

  .btn.invite {
    margin-top: 0;
    margin-bottom: 48px;
    width: calc(100% - 32px);
  }

  .list.group-options {
    margin-bottom: 104px;
    width: 100%;

    .item {
      display: flex;
      align-items: center;
      height: 48px;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      color: black;

      &:hover {
        img {
          transform: translateX(2px);
        }
      }

      img {
        margin: 0 0 0 auto;
        transition: all 0.2s ease-out;
      }
    }
  }
}

.modal-content.invite-friends {
  p {
    margin-bottom: 32px;
    padding: 0 16px;
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
  }

  .share-actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px;
    gap: 8px;

    .link-container {
      position: relative;
      bottom: unset;
      display: flex;
      justify-content: space-between;
      box-shadow: none;
      border: 1px solid rgba(0, 0, 0, 0.1);
      height: 48px;
      background: white;
      font-size: 16px;
      font-weight: 500;
      color: hsla(0, 0%, 0%, 0.7);
      gap: 8px;

      @media (max-width: 992px) {
        padding: 0 8px;
      }

      span {
        flex-grow: 1;
        overflow: hidden;
        font-size: 14px;
        font-weight: 600;
        line-height: 48px;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: hsla(0, 0%, 0%, 0.7);
      }

      .copy {
        margin: auto 0;
        box-shadow: none;
        border-radius: 4px;
        outline: none;
        height: 32px;
        padding: 0 16px;
        background: #edeff3;
        font-size: 12px;
        font-weight: 800;
        line-height: 32px;
        text-transform: uppercase;
        color: var(--color-primary);

        &:hover {
          transform: unset;
          background: var(--color-primary);
          color: white;
        }
      }
    }

    .btn.share-whats-app {
      display: none;

      @media (max-width: 1279px) {
        display: flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 0.3px;
      }

      img {
        margin-right: 8px;
      }
    }
  }
}

.btn-rapper {
  margin-bottom: 10vh;
}
</style>
