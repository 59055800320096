<template>
  <div class="or-divider">
    <span class="line"></span>
    <span class="text">{{ $t("components.or") }}</span>
    <span class="line"></span>
  </div>
</template>

<script>
export default {
  name: "OrDivider",
};
</script>

<style lang="scss" scoped>
.or-divider {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px 0;

  .line {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
  }

  .text {
    opacity: 0.4;
    padding: 0 vw(24px);
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
    line-height: 100%;
    text-align: center;
  }
}
</style>
