import axios, { AxiosRequestConfig } from "axios";
import useAuth from "@/componentables/auth";
import i18n from "@/i18n";
import Toast from "@/componentables/toast";

const ApiService = {
  _requestInterceptor: 0,
  _401interceptor: 0,
  isLoading: false,
  init(baseURL: string | undefined) {
    axios.defaults.baseURL = baseURL;
    axios.defaults.headers.common["Accept-Language"] = i18n.global.locale.value;
  },

  async setHeader() {
    const auth = useAuth();

    const token = await auth.getToken();

    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  async get(resource: string, params = {}) {
    await this.setHeader();

    return axios.get(resource, { params }).then((response) => {
      return response;
    });
  },

  post(
    resource: string,
    data: any,
    headers: AxiosRequestConfig | undefined = undefined,
  ) {
    return axios.post(resource, data, headers);
  },

  put(resource: string, data: any) {
    return axios.put(resource, data);
  },

  delete(resource: string) {
    return axios.delete(resource);
  },

  customRequest(data: AxiosRequestConfig) {
    return axios(data);
  },

  // TODO - fix this
  mountRequestInterceptor() {
    this._requestInterceptor = axios.interceptors.request.use(
      async (config) => {
        if (this.isLoading) {
          return config;
        }

        this.isLoading = true;

        return config;
      },
    );
  },

  mount401Interceptor() {
    const auth = useAuth();
    this._401interceptor = axios.interceptors.response.use(
      async (response) => {
        if (!this.isLoading) {
          return response;
        }

        this.isLoading = false;

        return response;
      },
      async (error) => {
        if (this.isLoading) {
          this.isLoading = false;
        }

        if (error.response.status === 401) {
          await auth.logout();
        } else {
          throw error;
        }
      },
    );
  },

  mountResponseInterceptor() {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const { t } = i18n.global;
        const toast = new Toast();
        const errorMessages = {
          401: t("errors.unauthorized"),
          403: t("errors.forbidden"),
          404: t("errors.not_found"),
          419: t("errors.page_expired"),
          429: t("errors.too_many_requests"),
          500: t("errors.server_error"),
        };

        if (
          error.response &&
          Object.keys(errorMessages).includes(error.response.status.toString())
        ) {
          toast.open(
            errorMessages[error.response.status as keyof typeof errorMessages],
          );
        }

        if (error.response.status === 422) {
          Object.values(error.response.data.errors).forEach((errors: any) => {
            errors.forEach((message: string) => {
              toast.open(message);
            });
          });
        }

        return Promise.reject(error);
      },
    );
  },

  unmount401Interceptor() {
    axios.interceptors.response.eject(this._401interceptor);
  },
};

export default ApiService;
