import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "modal-content" }
const _hoisted_2 = { class: "top-bar" }
const _hoisted_3 = { class: "members" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "profile-image-container" }
const _hoisted_6 = ["alt", "src"]
const _hoisted_7 = {
  key: 1,
  class: "profile-image-placeholder"
}
const _hoisted_8 = { class: "name" }
const _hoisted_9 = { class: "btn-rapper" }

import { IonContent, IonModal, useIonRouter } from "@ionic/vue";
import { onMounted, ref } from "vue";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import GroupService, { Group } from "@/services/group.service";
import { modalController } from "@ionic/core";
import ModalCloser from "@/components/ModalCloser.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GroupNavigationPopover',
  setup(__props) {

const ionRouter = useIonRouter();
// On mounted, get the groups the user is a member of (index groups)
const groups = ref<Group[]>([]);
const modal = ref();
onMounted(async () => {
  refreshGroups();
});

const refreshGroups = async () => {
  groups.value = await GroupService.index();
};

const cancel = () => {
  modal.value.$el.dismiss({
    dismissed: true,
  });
};

const goToGroup = (id: number, sport: string) => {
  cancel();

  Haptics.impact({ style: ImpactStyle.Medium });

  if (sport === "racing") {
    ionRouter.push({ name: "groups.formula1.show", params: { groupId: id } });
  } else {
    ionRouter.push({ name: "groups.show", params: { groupId: id } });
  }
};

function initials(fullName: string) {
  // Create an array from fullName
  const arrName = fullName.split(" ");

  // Select the first letter of the first name
  const firstName = fullName.charAt(0);

  // Select the first letter of the lastname
  const lastName = arrName[arrName.length - 1].charAt(0);

  // Return the initials
  return firstName.toUpperCase() + lastName.toUpperCase();
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_unref(IonModal), {
    ref_key: "modal",
    ref: modal,
    breakpoints: [0, 0.75, 1],
    "initial-breakpoint": 0.75,
    onDidPresent: refreshGroups,
    trigger: "open-group-navigation"
  }, {
    default: _withCtx(() => [
      _createVNode(_unref(IonContent), null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t("group.index.title")), 1)
            ]),
            _createElementVNode("div", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(groups.value, (group) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: group.id,
                  class: "member",
                  onClick: ($event: any) => (goToGroup(group.id, group.sport))
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    (group.avatar)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          alt: group.name,
                          src: group.avatarUrl
                        }, null, 8, _hoisted_6))
                      : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(initials(group.name)), 1))
                  ]),
                  _createElementVNode("span", _hoisted_8, _toDisplayString(group.name), 1)
                ], 8, _hoisted_4))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_router_link, {
                to: { name: 'groups.create' },
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(modalController).dismiss())),
                class: "btn"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("group.index.create-new-group")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      }),
      _createVNode(ModalCloser)
    ]),
    _: 1
  }, 512))
}
}

})