import { createStore } from "vuex";
import { profile } from "./profile.store";

export const store = createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    profile,
  },
});
