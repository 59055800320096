export default [
  {
    name: "profile.information",
    path: "profile/information",
    component: () => import("@/views/profile/ProfileInformation.vue"),
  },
  {
    name: "profile.subscription",
    path: "profile/subscription",
    component: () => import("@/views/profile/ProfileSubscription.vue"),
  },
  {
    name: "profile.password",
    path: "/profile/password",
    component: () => import("@/views/profile/ProfilePassword.vue"),
  },
  {
    name: "profile.show",
    path: "profile",
    component: () => import("@/views/profile/ProfileShow.vue"),
  },
];
